<div mat-dialog-content>
  <div class="container p-2">
    <div class="row justify-content-end">
      <div class="col-auto text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form">
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>Prontuário</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill">
            <input matInput formControlName="avaliacao" placeholder="Avaliação">
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <textarea matInput formControlName="descricao" placeholder="Descrição" [rows]="10"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</div>
