import { StoreConfig, Store, akitaConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Doctor } from '@app-model/doctor';

export interface UserInfo {
	id: string;
	name: string;
	email: string;
	hasPlan: boolean;
	firstName: string;
	role: string;
  clinicId: string;
  photo?: string;
}

export interface SessionState {
	isAuthenticated: boolean;
	userInfo: UserInfo;
  doctor: Doctor;
}

export function createInitialState(): SessionState {
	return {
		isAuthenticated: false,
		userInfo: null,
    doctor: null
	};
}

akitaConfig({
  resettable: true
})

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'sessionState' })
export class SessionStore extends Store<SessionState> {
	constructor() {
		super(createInitialState());
	}

	updateStatusPlan(status: boolean) {
		this.update(state => {
			return {
				...state,
				userInfo: {
					...state.userInfo,
					hasPlan: status
				}
			};
		});
	}

  updateDoctor(doctor: Doctor) {
    this.update(state => {
      return {
        ...state,
        doctor
      }
    })
  }
}
