import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Manager } from '@app-model/manager';
import { PageControl } from '@app-model/page-control';
import { ManagerService } from '@app-services/manager.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

interface ManagerChecked extends Manager {
  checked: boolean;
}

@Component({
  selector: 'app-table-manager',
  templateUrl: './table-manager.component.html',
  styleUrls: ['./table-manager.component.scss']
})
export class TableManagerComponent implements OnInit {

	@Input() searchTerm = '';
  @Input() idClinic?: string;
	@Input() loading = false;

  @Output() checkedManagers = new EventEmitter<ManagerChecked[]>()
  @Output() clickManager = new EventEmitter<Manager>();
  @Output() clickDeleteManager = new EventEmitter<Manager>();

  public list: ManagerChecked[] = [];

	public columns = [
		{
			slug: 'name',
			order: true,
			title: 'NOME DO ASSISTENTE ADMINISTRATIVO'
		},
		{
			slug: 'email',
			order: true,
			title: 'E-MAIL'
		},
		{
			slug: 'telephone',
			order: true,
			title: 'TELEFONE'
		},
		{
			slug: '',
			order: false,
			title: 'AÇÕES'
		},
	];

	public pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

  allComplete: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly managerService: ManagerService
  ) { }

  ngOnInit(): void {
    this.searchManager();
  }

  ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, idClinic, loading, removeManagerEvent } = changes;

		if ( (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) ||
        (idClinic?.currentValue !== idClinic?.previousValue) ) {
			this.onSearch();
		} else if(!loading?.currentValue) {
			this.onSearch();
    }

	}

  onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.searchManager();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.searchManager();
	}

  private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
		this.searchManager();
	}

  private searchManager() {
    if(this.idClinic){
      this.initOrStopLoading();

      this.managerService.paginate(this.pageControl, this.idClinic)
        .pipe(finalize(() => this.initOrStopLoading()))
        .subscribe(
          res => this.searchManagerResponse(res),
          err => this.searchManagerError(err)
        )
    }
  };

  private searchManagerResponse(res): void {
    this.list = res.data;
    this.pageControl.count = res.totalElements;

    this.handleCheckedManagers();
  }

  private searchManagerError(err): void {
    console.error(err)
  }

  private initOrStopLoading(): void {
    this.loading = !this.loading;
  };

  handleCheckedManagers(): void {
    const managers = this.list.filter((manager: any) => {
      if(manager.checked){
        return manager;
      }
    })

    this.checkedManagers.emit(managers);
  }

  handleClickManager(manager: Manager): void {
    this.clickManager.emit(manager);
  }

  updateAllComplete() {
    this.allComplete = this.list != null && this.list.every(t => t.checked);

    this.handleCheckedManagers();
  }

  someComplete(): boolean {
    if (this.list == null) {
      return false;
    }
    return this.list.filter(t => t.checked).length > 0 && !this.allComplete;
  }

  setAll(checked: boolean) {
    this.allComplete = checked;
    if (this.list == null) {
      return;
    }
    this.list.forEach(t => (t.checked = checked));

    this.handleCheckedManagers();
  }

}
