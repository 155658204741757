import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SessionStore, UserInfo } from './session.store';
import { environment } from '@env/environment';
import { tap, catchError, map, take, mergeMap, shareReplay } from 'rxjs/operators';
import { throwError, Observable, of, from } from 'rxjs';
import { applyTransaction, resetStores } from '@datorama/akita';
import { Router } from '@angular/router';
import {
	LoginStatus,
	PasswordRegistrationResponse,
	PasswordRegistrationRequest,
	DefaultStatus
} from '@app-model/login';
import { ToastrService } from 'ngx-toastr';
import { AmplifyService } from '@app-services/amplify.service';
import { CookieService } from 'ngx-cookie';
import { CookieKeys } from '@app-shared/enums/cookie-keys';
import { Doctor, DoctorDataDefaultResponse } from '@app-model/doctor';
import { DoctorService } from '@app-services/doctor.service';
import { UserInfoRoles } from '@app-shared/enums/others';
import { makeStateKey, TransferState } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	constructor(
		private sessionStore: SessionStore,
		private http: HttpClient,
		private amplifyService: AmplifyService,
		private router: Router,
		private toastr: ToastrService,
		private readonly cookieService: CookieService,
    private doctorService: DoctorService,
    private state: TransferState
	) {}

	setLoading(status: boolean) {
		this.sessionStore.setLoading(status);
	}

	checkEmailStatus(email: string): Observable<LoginStatus> {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/status/${email}`).pipe(
			map((response: LoginStatus) => response),
			tap(() => this.sessionStore.setLoading(false)),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	createUser(email: string) {
		this.sessionStore.setLoading(true);
		return this.http.get(`${environment.api}/login/createUser/${email}`).pipe(
			take(1),
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	resetPassword(userId: string, verifCode: string, password: string): Observable<PasswordRegistrationResponse> {
		this.setLoading(true);
		const request: PasswordRegistrationRequest = {
			userId,
			verifCode,
			password
		};
		return this.http.post(`${environment.api}/login/resetPassword`, request).pipe(
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	verifyUser(userId: string, verifCode: string): Observable<DefaultStatus> {
		return this.http
			.get(`${environment.api}/login/verifyUser/${userId}/${verifCode}`)
			.pipe(map((response: DefaultStatus) => response));
	}

	forgotPassword(email: string) {
		this.setLoading(true);
		return this.http.get(`${environment.api}/login/resetPassword/${email}`).pipe(
			take(1),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	isAuthenticated(): Observable<boolean> {
		return from(this.amplifyService.isAuthenticated()).pipe(
			tap(isAuthenticated => {
				this.sessionStore.update({ isAuthenticated });
			})
		);
	}

	updateIsAuthenticated(isAuthenticated: boolean) {
		applyTransaction(() => {
			this.sessionStore.setLoading(false);
			this.sessionStore.update({ isAuthenticated });
		});
	}

	async logout() {
		await this.amplifyService.logout();
		this.sessionStore.reset();
    // this.state.remove(makeStateKey(`${environment.api}/user/info`))
    this._clearTransferStateCache();
    resetStores();
		this.router.navigate(['/login']);

	}

  private _clearTransferStateCache(): void {
    const states = JSON.parse(this.state.toJson());
    Object.entries(states).forEach(([key, value]: any[]) => {
      this.state.remove(makeStateKey(value.url))
    })
  }

	registerUser(userId: string, verifCode: string, values): Observable<PasswordRegistrationResponse> {
		return this.http.post(`${environment.api}/login/registerNewUser/${userId}/${verifCode}`, values).pipe(
			take(1),
			map((response: PasswordRegistrationResponse) => response),
			tap(() => {
				this.sessionStore.setLoading(false);
			}),
			catchError(err => {
				this.setLoading(false);
				return throwError(err);
			})
		);
	}

	getUser(username?: string): Observable<UserInfo> {
		return this.sessionStore
			._select(state => state.userInfo)
			.pipe(
				mergeMap(userInfo => {
					if(username && username !== userInfo?.email) {
						return this.getUserFromBack();
					}

					if (userInfo) {
						return of(userInfo);
					}
					return this.getUserFromBack();
				})
			);
	}

	getUserFromBack(): Observable<UserInfo> {
		return this.http.get(`${environment.api}/user/info`).pipe(
			map((userInfo: UserInfo) => userInfo),
      tap(userInfo => this.sessionStore.update({ userInfo })),
      shareReplay()
		);
	}

  getDoctorById(idDoctor): Observable<Doctor> {
		return this.doctorService.getById(idDoctor)
      .pipe(
        take(1),
        map(response => {
          this.sessionStore.updateDoctor(response);
          return response
        })
      );
	}

	updateStatusPlan(status) {
		this.sessionStore.updateStatusPlan(status);
	}

	login(username: string, password: string, returnUrl?: string) {
		this.clearCookies();
		this.amplifyService.login(username, password).subscribe(
			response => {
				this.updateIsAuthenticated(true);
				this.getUser(username).subscribe(res => {
          if(res.role === UserInfoRoles.PATIENT){
            this.router.navigateByUrl('/');
          } else {
            this.router.navigateByUrl(returnUrl ? returnUrl : '/');
          }
        });
			},
			err => {
				this.setLoading(false);
				const { code } = err;
				let message: string;
				if (code === 'NotAuthorizedException') {
					message = 'Usuário ou senha inválido';
				} else {
					message = 'Não foi possível realizar o seu login';
				}

				this.toastr.error('', message, {
					closeButton: true,
					progressBar: true,
					positionClass: 'toast-bottom-center',
					extendedTimeOut: 5000,
					timeOut: 10000
				});
			}
		);
		// const authenticationData = {
		// 	Username: username,
		// 	Password: password
		// };
		// const authenticationDetails = new AuthenticationDetails(authenticationData);

		// const cognitoUser = this.cognitoService.getCognitoUser(username);
		// this.setLoading(true);
		// cognitoUser.authenticateUser(authenticationDetails, {
		// 	mfaRequired: () => {
		// 		throw new Error('Implementar MFA aqui');
		// 	},
		// 	onSuccess: (profile: CognitoUserSession) => {
		// 		this.router.navigateByUrl(returnUrl ? returnUrl : '/');
        // this.updateIsAuthenticated(true);
        // this.getUser().subscribe();
		// 	},
		// 	onFailure: err => {
		// 		this.setLoading(false);
		// 		const { code } = err;
		// 		let message: string;
		// 		if (code === 'NotAuthorizedException') {
		// 			message = 'Usuário ou senha inválido';
		// 		} else {
		// 			message = 'Não foi possível realizar o seu login';
		// 		}

		// 		this.toastr.error('', message, {
		// 			closeButton: true,
		// 			progressBar: true,
		// 			positionClass: 'toast-bottom-center',
		// 			extendedTimeOut: 5000,
		// 			timeOut: 10000
		// 		});
		// 	}
		// });

		// return undefined;
	}

	clearCookies(){
		this.cookieService.hasKey(CookieKeys.DOCTOR_SELECTED) && this.cookieService.remove(CookieKeys.DOCTOR_SELECTED);
		this.cookieService.hasKey(CookieKeys.CLINIC_SELECTED) && this.cookieService.remove(CookieKeys.CLINIC_SELECTED);
	}
}
