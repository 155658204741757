import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';
import { SharedComponentsModule } from '../shared-components.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogMedicalRecordComponent } from './dialog-medical-record.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DialogMedicalRecordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedFormsModule,
    SharedComponentsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
  ]
})
export class DialogMedicalRecordModule { }
