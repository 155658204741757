import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PageControl } from '@app-model/page-control';
import { PatientService } from '@app-services/patient.service';
import { PatientHistory, PatientHistoryPageableResponse } from '@app-model/patient';
import { TypeScheduling } from '@app-shared/enums/schedulings';
import { Utils } from '@app-shared/utils';

@Component({
	selector: 'app-table-patient-history',
	templateUrl: './table-patient-history.component.html',
	styleUrls: ['./table-patient-history.component.scss']
})
export class TablePatientHistoryComponent implements OnInit, OnChanges {
	@Input() searchTerm = '';
	@Input() idPatient = '';
  @Input() scheduleType: string|null = null;
	@Input() legend? = true;
  @Input() screen: 'PAINEL' | 'MKT' = 'PAINEL';
  @Input() patientCpf = '';
  @Input() patientCns = '';
  @Input() doctorId = '';

	loading = false;
	list!: PatientHistory[];
	typeScheduling = TypeScheduling;

	columns = [
		{
			slug: 'patientName',
			order: true,
			title: 'NOME DO PACIENTE'
		},
		{
			slug: 'doctorName',
			order: true,
			title: 'ESPECIALISTA'
		},
		{
			slug: 'start',
			order: true,
			title: 'DATA E HORÁRIO'
		},
		{
			slug: 'clinicName',
			order: true,
			title: 'LOCAL'
		}
	];

	pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'start',
		order: 'DESC',
		count: 0
	};

	constructor(private readonly router: Router, private readonly patientService: PatientService) {}

	ngOnInit(): void {
		this.getResults();
		this.columns[0].order = this.idPatient ? false : true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { searchTerm, patientCpf, patientCns, doctorId } = changes;
		if (searchTerm?.previousValue && searchTerm?.currentValue !== searchTerm?.previousValue) {
			this.onSearch();
		} else if (patientCpf?.currentValue !== patientCpf?.previousValue){
			this.onSearch();
    } else if (patientCns?.currentValue !== patientCns?.previousValue){
			this.onSearch();
    } else if (doctorId?.currentValue !== doctorId?.previousValue){
			this.onSearch();
    }
	}

	onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.getResults();
	}

	private getResults() {
		this.loading = true;
		this.pageControl.count = 0;

    const filters = {
      patientCpf: this.patientCpf || '',
      patientCns: this.patientCns || '',
      doctorId: this.doctorId || '',
    };

		if (this.idPatient) {
			this.patientService.paginateHistoryById(this.pageControl, filters, this.idPatient).subscribe(
				response => this.dataHandler(response),
				err => this.errorHandler()
			);
		} else {
			this.patientService.paginateHistory(this.pageControl, filters).subscribe(
				response => this.dataHandler(response),
				err => this.errorHandler()
			);
		}
	}

	private dataHandler(response: PatientHistoryPageableResponse) {
		this.pageControl.count = response.totalElements;
		this.list = response.data;
		this.list.map(s => {
			(s.date = Utils.patientHistoryDate(s.start, s.end)),
				(s.dateDayOfWeek = Utils.patientHistoryDayOfWeek(s.start, s.end)),
				(s.clinicAddress = Utils.formatAddress(s.address, s.number, s.neighborhood, s.city, s.uf));
		});
		this.loading = false;
	}

	private errorHandler() {
		this.loading = false;
		this.list = [];
		this.pageControl.count = 0;
	}

	private onSearch() {
		this.pageControl.value = this.searchTerm;
		this.pageControl.param = 'description';
		this.pageControl.page = 0;
		this.getResults();
	}

	pageEvent($event: any) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.getResults();
	}

	openScheduling(s: any) {
    if(this.screen === 'PAINEL'){
		  this.router.navigate(['painel/schedule/form', s.id], { queryParams: { sch: s.scheduleId } });
    }


    if(this.screen === 'MKT'){
		  this.router.navigate([`paciente/atendimentos/detalhes`, s.id], { queryParams: { sch: s.scheduleId } });
    }
	}

  setColorStatusPainel(type: string): string {
    if(type === 'SI'){
      return '#F49928';
    }

    if(type === 'COMMUN'){
      return '#3A9BD9';
    }

    return '#E0DEDC';
  }

  setColorStatusMkt(status: string): string {
    if(status === 'UNAVAIABLE'){
      return '#57A1A1';
    }

    if(status === 'ABSENT'){
      return '#F49928';
    }

    if(status === 'CANCELED'){
      return '#E54E4E';
    }

    return '#E0DEDC';
  }
}
