import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { environment } from '@env/environment';
import { Amplify } from 'aws-amplify';
import { signIn, fetchAuthSession, signOut, getCurrentUser, type JWT } from 'aws-amplify/auth';
import { from, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { CookieStorage } from '@app/cookie-storage';
import express from 'express';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class AmplifyService {
	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: any,
		@Optional() @Inject('REQUEST') req: express.Request
	) {
		const storage = new CookieStorage(
			{
				req
			},
			isPlatformBrowser(this.platformId)
		);

		Amplify.configure({
			Auth: {
        Cognito: {
          // region: environment.cognito.region,
          userPoolId: environment.cognito.UserPoolId,
          userPoolClientId: environment.cognito.ClientId,
          //storage
        }
			}
		});
	}

	/**
	 * Validar erros
	 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
	 */
	login(username: string, password: string): Observable<unknown> {
		return from(signIn({username, password}));
	}

	isAuthenticated(): Observable<boolean> {
		return from(getCurrentUser()).pipe(
			map(user => !!user),
			catchError(err => of(false))
		);
	}

	logout(): Promise<void> {
		return signOut();
	}

	getJwt(): Observable<JWT | null> {
		return from(fetchAuthSession()).pipe(
			map(response => {
				if (response.tokens) {
					return response.tokens.accessToken;
				}
				return null;
			})
		);
	}
}
