import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { TablePatientsComponent } from './table-patients.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
  declarations: [TablePatientsComponent],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [TablePatientsComponent]
})
export class TablePatientsModule {}
