import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-medical-record',
  templateUrl: './dialog-medical-record.component.html',
  styleUrls: ['./dialog-medical-record.component.scss']
})
export class DialogMedicalRecordComponent {
	public form: FormGroup;

  constructor(
		private readonly fb: FormBuilder,
		private readonly dialogRef: MatDialogRef<DialogMedicalRecordComponent>,
	) {}

  ngOnInit(): void {
		this.form = this.fb.group({
			avaliacao: [null, Validators.required],
			descricao: [null, [Validators.required]],
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.dirty;
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
