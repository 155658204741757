import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SharedFormsModule } from '@app-shared/forms/shared-forms.module';
import { SharedComponentsModule } from '../shared-components.module';
import { DialogNewManagerComponent } from './dialog-new-manager.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';



@NgModule({
  declarations: [DialogNewManagerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    SharedFormsModule,
    SharedComponentsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ]
})
export class DialogNewManagerModule { }
