import { Component, Input, type SimpleChanges } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { MeetService } from '@app-services/meet.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-table-dashboard',
  templateUrl: './table-dashboard.component.html',
  styleUrls: ['./table-dashboard.component.scss']
})
export class TableDashboardComponent {

  @Input()
  public loading: boolean = false;

  public list: any[] = [];

  public columns = [
		{
			slug: 'doctorName',
			order: true,
			title: 'ESPECIALISTA'
		},
		{
			slug: 'patientName',
			order: true,
			title: 'PACIENTE'
		},
		{
			slug: 'patientContact',
			order: true,
			title: 'CONTADO DO PACIENTE'
		},
		{
			slug: 'meetType',
			order: false,
			title: 'TIPO'
		},
		{
			slug: 'meetLink',
			order: false,
			title: 'LINK'
		},
		{
			slug: 'meetStartDate',
			order: false,
			title: 'DATA ATENDIMENTO'
		},
	];

	public pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'createdAt',
		order: 'DESC',
		count: 0
	};

	constructor(
		private readonly meetService: MeetService
	) {}

  ngOnInit(): void {
    this.searchMeet();
  }

  public initOrStopLoading(): void {
    this.loading = !this.loading;
  }

  ngOnChanges(changes: SimpleChanges): void {
		const { loading } = changes;

		if(!loading?.currentValue) {
			this.onSearch();
    }

	}

  onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
    this.searchMeet();
	}

	pageEvent($event) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
    this.searchMeet();
	}

  private onSearch() {
		// this.pageControl.value = this.searchTerm;
		this.pageControl.page = 0;
    this.searchMeet();
	}

  private searchMeet() {
    this.initOrStopLoading();

    this.meetService.paginate(this.pageControl)
      .pipe(finalize(() => this.initOrStopLoading()))
      .subscribe({
        next: res => this.searchMeetResponse(res),
        error: err => this.searchMeetError(err)
      })
  };

  private searchMeetResponse(res): void {
    this.list = res.data;
    this.pageControl.count = res.totalElements;
  }

  private searchMeetError(err): void {
    console.error(err)
  }
}
