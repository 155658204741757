import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScheduleTypeMap } from '@app-model/clinic';
import { ScheduleTimeStartEnd, Settings, Slot } from '@app-model/schedule';
import { DayOfWeek, NumberOfDay } from '@app-shared/enums/dayOfWeek';
import { FormControlStatus } from '@app-shared/enums/form';
import { hourMask } from '@app-shared/masks/time.mask';
import { Utils } from '@app-shared/utils';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-schedule-agenda-da-si',
  templateUrl: './dialog-schedule-agenda-da-si.component.html',
  styleUrls: ['./dialog-schedule-agenda-da-si.component.scss']
})
export class DialogScheduleAgendaDaSiComponent implements OnInit {
	form: FormGroup;
  schedules: FormGroup;
  scheduleId: string;

  numberOfDay = NumberOfDay;

  hourMask = {
		guide: true,
		mask: hourMask
  }

  infoScheduleTime = 'Em caso de pausa entre os atendimentos, é necessário criar mais um horário de retorno e saída na agenda.'
  infoScheduleType = 'Atenção: os horários de atendimento domiciliar deve ser diferente dos demais.';

  slots: Slot[] = [
    {
      value: 15,
      description: '15 Minutos'
    },
    {
      value: 30,
      description: '30 Minutos'
    },
    {
      value: 45,
      description: '45 Minutos'
    },
    {
      value: 60,
      description: '1 hora'
    }
  ]

  slotsDomiciliar: Slot[] = [
    {
      value: 30,
      description: '30 Minutos'
    },
    {
      value: 60,
      description: '1 hora'
    },
    {
      value: 90,
      description: '1 hora e 30 minutos'
    },
    {
      value: 120,
      description: '2 horas'
    },
  ]

  scheduleTypeMapGlobal: ScheduleTypeMap = {
    commum: true,
    online: true,
    homecare: false,
  };

  daysSelected: number[] = [];
  scheduleTypeMap: ScheduleTypeMap = {
    commum: false,
    online: false,
    homecare: false,
  };
  startTime: string;
  endTime: string;

  times: string[] = [];
  settings: Settings[] = [];

  constructor(
    private el: ElementRef,
    private readonly fb: FormBuilder,
    private readonly toastr: ToastrService,
    private readonly dialogRef: MatDialogRef<DialogScheduleAgendaDaSiComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    // this.scheduleTypeMap = this.data.scheduleTypeMap;

    // this.schedules = this.fb.group({
    //   sunday: this.fb.array([this.setScheduleDay()]),
    //   sundayCheck: [false],
    //   monday: this.fb.array([this.setScheduleDay()]),
    //   mondayCheck: [false],
    //   tuesday: this.fb.array([this.setScheduleDay()]),
    //   tuesdayCheck: [false],
    //   wednesday: this.fb.array([this.setScheduleDay()]),
    //   wednesdayCheck: [false],
    //   thursday: this.fb.array([this.setScheduleDay()]),
    //   thursdayCheck: [false],
    //   friday: this.fb.array([this.setScheduleDay()]),
    //   fridayCheck: [false],
    //   saturday: this.fb.array([this.setScheduleDay()]),
    //   saturdayCheck: [false],
    // });

    this.form = this.fb.group({
      slot: [30],
      slotHomecare: [60],
      schedules: [null]
    });

    this.fillData(this.data);
    this.createTimes();
  }

  createTimes(): void {
    const interval = this._getInterval(this.scheduleTypeMap);
    this.times = [];

    for (let i = 0; i < 24; i++) {
      const hour = i > 9 ? `${i}` : `0${i}`;
      if(interval === 15 || interval === 45){
        this.times.push(`${hour}:00`);
        this.times.push(`${hour}:15`);
        this.times.push(`${hour}:30`);
        this.times.push(`${hour}:45`);
      }

      if(interval === 30 || interval === 90){
        this.times.push(`${hour}:00`);
        this.times.push(`${hour}:30`);
      }

      if(interval === 60 || interval === 120){
        this.times.push(`${hour}:00`);
      }
    }
  }

  setScheduleDay(): FormGroup {
    return this.fb.group({
      homecare: {value: this.isOnlyHomecare(), disabled: this.isOnlyHomecare()},
      start: [''],
      end: ['']
    })
  }

  addScheduleDay(day: string): void {
    const items = this.schedules.get(day) as FormArray;
    items.push(this.setScheduleDay());
  }

  removeScheduleDay(day: string, index: number): void {
    const items = this.schedules.get(day) as FormArray;
    items.removeAt(this.schedules.get(day).value.findIndex(i => i === index));

    if(!items.length){
      this.addScheduleDay(day);
    }
  }

  fillData(data): void {
    const { scheduleId, settings, scheduleTypeMap } = data;

    this.scheduleTypeMapGlobal = scheduleTypeMap;
    this.scheduleId = scheduleId;
    this.settings = settings.map(setting => ({
      ...setting,
      start: this._dateToTimeString(setting.start),
      end: this._dateToTimeString(setting.end)
    }));

    // settings.map(({ weekDays, ...setting }) => {
    //   if(this._isOnlyHomecare()){
    //     this.form.patchValue({ slot: 60 })
    //     this.form.get('slot').disable()
    //   } else {
    //     this.form.patchValue({ slot: this._getInterval({...setting, ...weekDays}) })
    //   }

    //   if(setting.type === "SI"){
    //     weekDays.map(day => {
    //       this._setDataScheduleDate(day, setting.start, setting.end, setting.scheduleTypes.homecare);
    //     })
    //   }
    // })
  }

  // private _getInterval(settings: Settings){
  //   if(!settings.scheduleTypes.homecare){
  //     return settings.interval;
  //   }

  //   return this.form.get('slot').value;
  // }

  private _getInterval(scheduleTypeMap): number {
    if(scheduleTypeMap.homecare) {
      return this.form.get('slotHomecare').value;
    }

    return this.form.get('slot').value;
  }

  private _setDataScheduleDate(day: number, start: Date, end: Date, homecare: boolean): void {
    if(start && end){
      const strStart = this._dateToTimeString(start);
      const strEnd = this._dateToTimeString(end);
      const strDay = Utils.dayOfWeekToEnglish(day).toLowerCase();

      if(this._isScheduleCheck(day)){
        this.addScheduleDay(strDay);
      }

      if(!this._isScheduleCheck(day)){
        this._checkScheduleDate(day);
      }

      const schedule = this.schedules.get(strDay) as FormArray;
      schedule.controls[schedule.length - 1].patchValue({
        start: strStart, end: strEnd, homecare: homecare || this.isOnlyHomecare()
      });
    }
  }

  private _checkScheduleDate(day: number): void {
    if(day === NumberOfDay.SUNDAY){
      return this.schedules.get('sundayCheck').setValue(true);
    }

    if(day === NumberOfDay.MONDAY){
      return this.schedules.get('mondayCheck').setValue(true);
    }

    if(day === NumberOfDay.TUESDAY){
      return this.schedules.get('tuesdayCheck').setValue(true);
    }

    if(day === NumberOfDay.WEDNESDAY){
      return this.schedules.get('wednesdayCheck').setValue(true);
    }

    if(day === NumberOfDay.THURSDAY){
      return this.schedules.get('thursdayCheck').setValue(true);
    }

    if(day === NumberOfDay.FRIDAY){
      return this.schedules.get('fridayCheck').setValue(true);
    }

    if(day === NumberOfDay.SATURDAY){
      return this.schedules.get('saturdayCheck').setValue(true);
    }
  }

  private _isScheduleCheck(day: number): boolean {
    if(day === NumberOfDay.SUNDAY){
      return this.schedules.get('sundayCheck').value;
    }

    if(day === NumberOfDay.MONDAY){
      return this.schedules.get('mondayCheck').value;
    }

    if(day === NumberOfDay.TUESDAY){
      return this.schedules.get('tuesdayCheck').value;
    }

    if(day === NumberOfDay.WEDNESDAY){
      return this.schedules.get('wednesdayCheck').value;
    }

    if(day === NumberOfDay.THURSDAY){
      return this.schedules.get('thursdayCheck').value;
    }

    if(day === NumberOfDay.FRIDAY){
      return this.schedules.get('fridayCheck').value;
    }

    if(day === NumberOfDay.SATURDAY){
      return this.schedules.get('saturdayCheck').value;
    }

    return false;
  }

  private _dateToTimeString(date: Date): string {
    return moment(date).format('HH:mm');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if(this.settings.length) {
      this.settings.forEach(setting => this._setInterval(setting));
    }

    this.dialogRef.close(this.settings);
  }

  private _setInterval(setting: Settings): void {
    if(setting.scheduleTypes.homecare) {
      setting.interval = this.form.get('slotHomecare').value;
    } else {
      setting.interval = this.form.get('slot').value;
    }
  }

  // isValidTime(timeString: string, day: string, event: 'start' | 'end') {
  //   if(timeString){
  //     const time = moment('1970-01-01 ' + timeString);

  //     const scheduleArray = this.schedules.get(day).value as Array<{start: string, end: string}>;
  //     const schedule = this.schedules.get(day) as FormArray;

  //     const isBetweenOrSame = (): boolean => {
  //       return scheduleArray.some(({start, end}, index) => {
  //         if(index !== scheduleArray.length - 1){
  //           return time.isBetween(moment('1970-01-01 ' + start), moment('1970-01-01 ' + end))
  //             || time.isSame(moment('1970-01-01 ' + start))
  //             || time.isSame(moment('1970-01-01 ' + end))
  //         }
  //       })
  //     }

  //     if(event === 'start'){
  //       if(scheduleArray.length > 1 && isBetweenOrSame()) {

  //         this.toastr.warning('Horário não permitido');
  //         schedule.controls[schedule.length - 1].patchValue({
  //           start: null, end: null
  //         });
  //       }
  //     }

  //     if(event === 'end'){
  //       const {start, end} = scheduleArray[scheduleArray.length - 1];
  //       const startTime = moment('1970-01-01 ' + start);

  //       if(time.isBefore(startTime) || time.isSame(startTime)){

  //         this.toastr.warning('Horário não permitido');
  //         schedule.controls[schedule.length - 1].patchValue({
  //           start: start, end: null
  //         });
  //       } else if (scheduleArray.length > 1 && isBetweenOrSame()){

  //         this.toastr.warning('Horário não permitido');
  //         schedule.controls[schedule.length - 1].patchValue({
  //           start: start, end: null
  //         });
  //       }
  //     }
  //   }
  // }

  // private _setScheduleResultData(schedules) {

  //   const schedule = [];

  //   const {
  //     sunday, sundayCheck,
  //     monday, mondayCheck,
  //     tuesday, tuesdayCheck,
  //     wednesday, wednesdayCheck,
  //     thursday, thursdayCheck,
  //     friday, fridayCheck,
  //     saturday, saturdayCheck,
  //   } = schedules

  //   if(sundayCheck){
  //     sunday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.SUNDAY, item))
  //     })
  //   }


  //   if(mondayCheck){
  //     monday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.MONDAY, item))
  //     })
  //   }

  //   if(tuesdayCheck){
  //     tuesday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.TUESDAY, item))
  //     })
  //   }

  //   if(wednesdayCheck){
  //     wednesday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.WEDNESDAY, item))
  //     })
  //   }

  //   if(thursdayCheck){
  //     thursday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.THURSDAY, item))
  //     })
  //   }

  //   if(fridayCheck){
  //     friday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.FRIDAY, item))
  //     })
  //   }

  //   if(saturdayCheck){
  //     saturday.map(item => {
  //       schedule.push(this._setScheduleSetting(DayOfWeek.SATURDAY, item))
  //     })
  //   }

  //   return schedule;
  // }

  // private _setScheduleSetting(day: string, time: ScheduleTimeStartEnd): Settings {
  //   return {
  //     scheduleId: this.data.scheduleId,
  //     weekDays: [Utils.DayOfWeekToNumber(day)],
  //     start: time.start,
  //     end: time.end,
  //     interval: time.homecare ? 60 : this.form.get('slot').value,
  //     type: 'SI',
  //     scheduleTypes: {
  //       commum: time.homecare ? !time.homecare : this.scheduleTypeMap.commum,
  //       online: time.homecare ? !time.homecare : this.scheduleTypeMap.online,
  //       homecare: time.homecare
  //     }
  //   }
  // }

  // private _summarizeScheduleResultData(scheduleSettings: Settings[]): Settings[] {
  //   const result: Settings[] = [];

  //   scheduleSettings.map((setting, index) => {
  //     if(this.data.settings && this.data.settings[index]){
  //       setting.id = this.data.settings[index].id;
  //     }else{
  //       setting.id = null;
  //     }

  //     if(result.length > 0){
  //       const index = result.findIndex(res =>
  //         res.start === setting.start &&
  //         res.end === setting.end &&
  //         res.scheduleTypes.homecare === setting.scheduleTypes.homecare
  //       );

  //       if(index >= 0) {
  //         result[index].weekDays.push(setting.weekDays[0]);
  //       }else{
  //         result.push(setting);
  //       }
  //     }

  //     if(result.length === 0) {
  //       result.push(setting);
  //     }
  //   })

  //   return result;
  // }

  isOnlyHomecare(): boolean {
    const { commum, online, homecare } = this.scheduleTypeMapGlobal;

    return homecare && !commum && !online;
  }

  handleChangeCheckbox(event): void {
    const { value, checked } = event.target;

    if(checked) {
      this.daysSelected.push(Number(value));
      this.daysSelected.sort();
    } else {
      const index = this.daysSelected.indexOf(Number(value));
      if (index !== -1) {
        this.daysSelected.splice(index, 1);
      }
    }
  }

  handleChangeTime(value, type: string): void {
    this[type] = value;
  }

  handleChangeScheduleTypeMap({checked, source}): void {
    const { value } = source;
    this.scheduleTypeMap[value] = checked;

    this.createTimes();
  }

  private _hasScheduleSelected(): boolean {
    const { commum, online, homecare } = this.scheduleTypeMap;

    return commum || online || homecare;
  }

  submitScheduleTime(): void {
    if(!this.daysSelected.length){
      this.toastr.warning('Atenção: é preciso selecionar os dias correspondentes para o criar um horário de atendimento.');
    }
    //  else if (!this._hasScheduleSelected()) {
    //   this.toastr.warning('Atenção: é preciso informar um tipo de atendimento');
    // }
     else if (!this.startTime || !this.endTime) {
      this.toastr.warning('Atenção: é preciso informar um horário válido');
    } else {
      const setting: Settings = {
        scheduleId: this.data.scheduleId,
        weekDays: [...this.daysSelected],
        start: this.startTime,
        end: this.endTime,
        interval: this._getInterval(this.scheduleTypeMap),
        type: 'SI',
        // scheduleTypes: {...this.scheduleTypeMap},
        scheduleTypes: {online: true, commum: false, homecare: false},
      }

      this.summarizeSettings(setting);
    }
  }

  private summarizeSettings(setting: Settings): void {
    const start = moment('1970-01-01 ' + setting.start);
    const end = moment('1970-01-01 ' + setting.end);

    if(end.isBefore(start)){
      this.toastr.warning('Horário não permitido');
      return;
    }

    if(!this.settings.length){
      this.settings.push(setting);
      this._clearScheduleValues();
      return;
    }

    if(this._isValidTime(setting)){
      const index = this.settings.findIndex(
        set => set.start === setting.start && set.end === setting.end
      );

      if(index >= 0){

        const { commum, online, homecare } = setting.scheduleTypes;
        if(
          commum === this.settings[index].scheduleTypes.commum &&
          online === this.settings[index].scheduleTypes.online &&
          homecare === this.settings[index].scheduleTypes.homecare
        ) {
          setting.weekDays.forEach(day => {
            if(!this.settings[index].weekDays.includes(day)){
              this.settings[index].weekDays.push(day);
            }
          })
        } else {
          this.settings.push(setting);
        }
        this._clearScheduleValues();
      } else {
        this.settings.push(setting);
        this._clearScheduleValues();
      }
    } else {
      this.toastr.warning('Horário não permitido');
    }

  }

  private _isValidTime({ start, end, weekDays }: Settings): boolean {

    const equalDays = this.settings.filter(setting => setting.weekDays.some(day => weekDays.includes(day)) && setting )

    const isBetweenOrSame = (time): boolean => {
      time = moment('1970-01-01 ' + time);
      return equalDays.some(({start, end}) => (
        time.isBetween(moment('1970-01-01 ' + start), moment('1970-01-01 ' + end))
        || time.isSame(moment('1970-01-01 ' + start))
        || time.isSame(moment('1970-01-01 ' + end))
      ))
    }

    const isInside = (start, end): boolean => {
      const startTime = moment('1970-01-01 ' + start);
      const endTime = moment('1970-01-01 ' + end);
      return equalDays.some(({start, end}) => (
        startTime.isBefore(moment('1970-01-01 ' + start))
        && endTime.isAfter(moment('1970-01-01 ' + end))
      ))
    }

    if(equalDays.length){
      return !(isBetweenOrSame(start) || isBetweenOrSame(end) || isInside(start, end));
    } else {
      return !equalDays.length;
    }

  }

  removeTag(index): void {
    this.settings.splice(index, 1);
  }

  tagContent(setting: Settings): string {
    let content = '';

    setting.weekDays.forEach(day => {
      content += `${Utils.numberToDayOfWeekPortuguese(day)}, `
    })

    content += `${setting.start} às ${setting.end}`;

    if(setting.scheduleTypes.commum && setting.scheduleTypes.online){
      content += ' - Presencial + Online'
    }else if(setting.scheduleTypes.commum){
      content += ' - Presencial'
    }else if(setting.scheduleTypes.online){
      content += ' - Online'
    }else if(setting.scheduleTypes.homecare){
      content += ' - Domiciliar'
    }

    return content;
  }

  isHomecare(): boolean {
    return this.scheduleTypeMap.homecare;
  }

  isCommonOrOnline(): boolean {
    return this.scheduleTypeMap.commum || this.scheduleTypeMap.online;
  }

  private _clearScheduleValues(): void {
    this.daysSelected = [];
    this.scheduleTypeMap = {
      commum: false,
      online: false,
      homecare: false,
    };

    this.el.nativeElement.querySelector('#sunday').checked = false;
    this.el.nativeElement.querySelector('#monday').checked = false;
    this.el.nativeElement.querySelector('#tuesday').checked = false;
    this.el.nativeElement.querySelector('#wednesday').checked = false;
    this.el.nativeElement.querySelector('#thursday').checked = false;
    this.el.nativeElement.querySelector('#friday').checked = false;
    this.el.nativeElement.querySelector('#saturday').checked = false;
  }

}
