import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clinic } from '@app-model/clinic';
import { Doctor } from '@app-model/doctor';
import { PageControl } from '@app-model/page-control';
import { DoctorService } from '@app-services/doctor.service';
import { ScheduleService } from '@app-services/schedule.service';
import { DialogSearchType } from '@app-shared/enums/others';

@Component({
	templateUrl: './dialog-search-schedule.component.html',
	styleUrls: ['./dialog-search-schedule.component.scss']
})
export class DialogSearchScheduleComponent implements OnInit {

	doctors: Doctor[] = [];
	clinics: Clinic[] = [];
	orderField = 'name';
	order = 'ASC';

	searchQuery = '';

	loading = false;

	type = '';

	dialogSearchType = DialogSearchType;

	pageControl: PageControl = {
		page: 0,
		orderField: 'name',
		order: 'ASC',
		count: 0
	};

	columns = [
		{
			slug: 'name',
			order: true,
			title: 'ESPECIALISTA'
		},
	];

	constructor(
		private readonly dialogRef: MatDialogRef<DialogSearchScheduleComponent>,
		private readonly doctorService: DoctorService,
		private readonly scheduleService: ScheduleService,
		@Inject(MAT_DIALOG_DATA) data
	) { this.type = data.type; }

	ngOnInit(): void {
		this.getResults();

    if(!this.isTypeOnlyDoctor){
      this.columns.push(		{
        slug: 'address',
        order: false,
        title: 'LOCAL'
      })
    }
	}

	close() {
		this.dialogRef.close(false);
	}

	selectDoctor(d, s) {
		const data = { doctor: d, schedule: s };
		this.dialogRef.close(data);
	}

  selectOnlyDoctor(d) {
		const data = { doctor: d };
		this.dialogRef.close(data);
  }

	selectClinic(c) {
		this.dialogRef.close(c);
	}

	private getResults() {
		this.loading = true;
		this.pageControl.count = 0;
		if(this.isTypeDoctor || this.isTypeOnlyDoctor){
			this.doctorService.paginate(this.pageControl).subscribe(
				response => this.dataHandler(response),
				error => this.errorHandler(error)
			);
		} else {
			this.scheduleService.paginateClinics(this.pageControl).subscribe(
				response => this.dataHandler(response),
				error => this.errorHandler(error)
			);
		}

	}

	private dataHandler(response) {
		this.isTypeDoctor || this.isTypeOnlyDoctor ? this.doctors = response.data : this.clinics = response.data;
		this.pageControl.count = response.totalElements;
		this.loading = false;
	}

	private errorHandler(error) {
		this.isTypeDoctor || this.isTypeOnlyDoctor ? this.doctors = [] : this.clinics = [];
		this.loading = false;
		this.pageControl.count = 0;
	}

	get isTypeDoctor() {
		return this.type === this.dialogSearchType.DOC;
	}

  get isTypeOnlyDoctor() {
		return this.type === this.dialogSearchType.ONLY_DOC;
	}

	onSearch() {
		this.pageControl.value = this.searchQuery;
		this.pageControl.page = 0;
		this.getResults();
	}

	onClickOrderBy(slug: string, order: boolean) {
		if (!order) {
			return;
		}

		if (this.pageControl.orderField === slug) {
			this.pageControl.order = this.pageControl.order === 'ASC' ? 'DESC' : 'ASC';
		} else {
			this.pageControl.order = 'ASC';
			this.pageControl.orderField = slug;
		}
		this.pageControl.page = 0;
		this.getResults();
	}
}
