import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageControl } from '@app-model/page-control';
import { MedicalRecord } from '@app-model/patient';
import { DialogMedicalRecordComponent } from '../dialog-medical-record/dialog-medical-record.component';
import { MedicalRecordService } from '@app-services/medical-record.service';
import { finalize } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-medical-records',
  templateUrl: './medical-records.component.html',
  styleUrls: ['./medical-records.component.scss']
})
export class MedicalRecordsComponent {

  @Input() patientId: string;
  @Input() doctorId: string;

  public loading: boolean = false;

  public records: MedicalRecord[] = [];

	public pageControl: PageControl = {
		limit: 10,
		page: 0,
		orderField: 'id',
		order: 'DESC',
		count: 0
	};

  constructor(
    private readonly _dialog: MatDialog,
    private readonly _medicalRecordService: MedicalRecordService,
    private readonly _toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getMedicalRecords();
  }

  private _initOrStopLoading(): void {
    this.loading = !this.loading;
  }

  private getMedicalRecords(): void {
    this._initOrStopLoading();
    this._medicalRecordService.paginate(this.pageControl, this.patientId)
      .pipe(finalize(() => this._initOrStopLoading()))
      .subscribe({
        next: (response) => {
          this.records = response.data;
          this.pageControl.count = response.totalElements;
        },
        error: (error) => {
          console.log(error);
        }
      });
  }

	public pageEvent($event: any) {
		this.pageControl.page = $event.pageIndex;
		this.pageControl.limit = $event.pageSize;
		this.getMedicalRecords();
	}

  public openMedicalRecordDialog(): void {
    const config: MatDialogConfig = {
      minWidth: '900px',
      // height: '600px'
    };
    this._dialog.open(DialogMedicalRecordComponent, config)
      .afterClosed()
      .subscribe({
        next: (res) => {
          if (res) {
            this._postMedicalRecord(res);
          }
        }
      });
  }

  private _postMedicalRecord(medicalRecord: MedicalRecord): void {
    this._initOrStopLoading();
    this._medicalRecordService.postMedicalRecord(this.doctorId, this.patientId, medicalRecord)
      .pipe(finalize(() => this._initOrStopLoading()))
      .subscribe({
        next: (response) => {
          if (response.success) {
            this._toastr.success(response.message);
            this.getMedicalRecords();
            return;
          }

          this._toastr.error(response.message);
        },
        error: (error) => {
          this._toastr.error(error.error.message)
        }
      });
  }

}
