<mat-dialog-content>
  <div class="container px-0">
    <form [formGroup]="form" appInvalidControlScroll>
      <div class="row">
        <div class="col-md-12">
          <div class="row align-items-start">
            <div class="col-md">
              <h1>Agenda da Si</h1>
            </div>
            <div class="col-md-auto text-right">
              <button type="button" class="btn btn-close" (click)="onNoClick()">
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-6 mt-2" *ngIf="!isOnlyHomecare()">
          <div class="label mb-2">
            <i class="fal fa-stopwatch me-2"></i>
            <span>Defina o tempo de atendimento</span>
          </div>
          <mat-form-field>
            <mat-label>Selecionar tempo</mat-label>
            <mat-select formControlName="slot" (ngModelChange)="createTimes()">
              <mat-option *ngFor="let slot of slots" [value]="slot.value">
                {{slot.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 mt-2" *ngIf="scheduleTypeMapGlobal.homecare">
          <div class="label mb-2">
            <i class="fal fa-stopwatch me-2"></i>
            <span>Tempo de Atendimento Domiciliar</span>
          </div>
          <mat-form-field>
            <mat-label>Selecionar tempo</mat-label>
            <mat-select formControlName="slotHomecare" (ngModelChange)="createTimes()">
              <mat-option *ngFor="let slot of slotsDomiciliar" [value]="slot.value">
                {{slot.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="label mb-2">
            <i class="fal fa-calendar-check me-2"></i>
            <span>Configurar Atendimento</span>
          </div>
        </div>
      </div>
      <section class="row mt-2">
        <article class="col-auto">
          <input type="checkbox" id="sunday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.SUNDAY">
          <div><span>Domingo</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="monday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.MONDAY">
          <div><span>Segunda</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="tuesday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.TUESDAY">
          <div><span>Terça</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="wednesday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.WEDNESDAY">
          <div><span>Quarta</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="thursday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.THURSDAY">
          <div><span>Quinta</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="friday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.FRIDAY">
          <div><span>Sexta</span></div>
        </article>
        <article class="col-auto">
          <input type="checkbox" id="saturday" (change)="handleChangeCheckbox($event)" [value]="numberOfDay.SATURDAY">
          <div><span>Sábado</span></div>
        </article>
      </section>
      <!-- <div class="row mt-5 ps-4">
        <div class="col-md-12">
          <div class="label mb-2">
            <span class="me-2">Tipo de Atendimento</span>
            <i class="fal fa-info-circle" matTooltipHideDelay="1000" [matTooltip]="infoScheduleType"></i>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.commum" value="commum"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isHomecare()" *ngIf="!isOnlyHomecare()">
            Presencial
          </mat-checkbox>
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.online" value="online"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isHomecare()" *ngIf="!isOnlyHomecare()">
            Online
          </mat-checkbox>
          <mat-checkbox class="me-2" [checked]="scheduleTypeMap.homecare" value="homecare"
            (change)="handleChangeScheduleTypeMap($event)" [disabled]="isCommonOrOnline()"
            *ngIf="scheduleTypeMapGlobal.homecare">
            Domiciliar
          </mat-checkbox>
        </div>
      </div> -->
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="label mb-2">
            <span class="me-2">Horários de Atendimento</span>
            <i class="fal fa-info-circle" matTooltipHideDelay="1000" [matTooltip]="infoScheduleTime"></i>
          </div>
        </div>
        <!-- <div class="col-md-8 info-container mx-3 py-2 px-3">
          Com a Si você concentra a sua agenda em um só lugar, sem conflito. Configure seu calendário e defina horários
          exclusivos para os pacientes do Saúde Integral.
        </div> -->
        <div class="col-md-12 mt-3">
          <div class="row align-items-center">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Abre à(s)</mat-label>
                <mat-select (valueChange)="handleChangeTime($event, 'startTime')">
                  <mat-option *ngFor="let time of times" [value]="time">
                    {{time}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width">
                <input matInput min="00:00" max="23:00" step="900" type="time" placeholder="Abre à(s)"
                  mask="00:00" (change)="handleChangeTime($event.target.value, 'startTime')">
              </mat-form-field> -->
            </div>
            <div class="col-md-4">
              <mat-form-field>
                <mat-label>Fecha à(s)</mat-label>
                <mat-select (valueChange)="handleChangeTime($event, 'endTime')">
                  <mat-option *ngFor="let time of times" [value]="time">
                    {{time}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-form-field class="example-full-width">
                <input matInput type="time" placeholder="Fecha à(s)" mask="00:00"
                  (change)="handleChangeTime($event.target.value, 'endTime')">
              </mat-form-field> -->
            </div>
            <div class="col-md-4">
              <button mat-flat-button color="primary" style="color: #ffffff; background-color: #57A1A1;" (click)="submitScheduleTime()">
                <i class="fal fa-plus"></i>
                Adicionar horário
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let setting of settings; let i = index">
          <div class="col-auto mt-2">
            <div class="tags-container">
              <div (click)="removeTag(i)" style="cursor: pointer;"><i class="fal fa-times me-2"></i></div>
              <span>{{tagContent(setting)}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit()" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
