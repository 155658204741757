<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row justify-content-end">
      <div class="col-auto text-right">
        <button type="button" class="btn btn-close" (click)="onNoClick()">
        </button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>Especialista</h1>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <h2 class="mt-2">Foto de Perfil</h2>

              <div class="row">
                <div class="col-md-12">
                  <div *ngIf="uploadMessage" class="file-upload-status"
                    [ngClass]="{ 'warning-box': uploadError, 'success-box': !uploadError }">
                    <i class="fal fa-{{ uploadError ? 'exclamation-triangle' : 'cloud-upload' }}"></i>{{ uploadMessage
                    }}
                  </div>
                  <ng-container *ngIf="!base64Image">
                    <ngx-dropzone class="ng2FileDrop" [ngClass]="{ 'has-file': hasFile }" (change)="fileOver($event)"
                      style="padding: 0;">
                      <ngx-dropzone-label>
                        <i class="icon fal fa-upload"></i>
                        <em>Arraste e solte o arquivo aqui</em>
                        <button class="btn btn-outline-light" type="button" style="pointer-events: none;">
                          <span>SELECIONAR ARQUIVO</span>
                        </button>
                      </ngx-dropzone-label>
                    </ngx-dropzone>
                    <div class="text-info-upload ms-0">
                      Tamanho recomendado: 500 x 500 pixels. Peso máximo: 4 MB.
                    </div>
                  </ng-container>

                  <ng-container *ngIf="base64Image">
                    <div class="container my-3">
                      <div class="row">
                        <div class="d-flex col justify-content-center align-items-center">
                          <div class="bg-preview" style="background-image: url({{base64Image}});">
                            <div class="remove-upload" (click)="removePhoto()">
                              <i class="material-icons">delete_forever</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-12">
          <div class="radio-container">
            <label id="example-radio-group-label">Forma de tratamento</label>
            <mat-radio-group formControlName="treatmentPronoun" aria-label="Escolha uma opção">
              <mat-radio-button class="radio-item" value="Dr">Dr</mat-radio-button>
              <mat-radio-button class="radio-item" value="Dra">Dra</mat-radio-button>
            </mat-radio-group>
            <mat-hint class="radio-btn-hint"
              *ngIf="form.get('treatmentPronoun').dirty && !form.get('treatmentPronoun').value">Campo
              Obrigatório</mat-hint>
          </div>
        </div> -->
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="name" placeholder="Nome completo">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="email" placeholder="Email" email>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="telephone" placeholder="Telefone"
              mask="(00) 0000-0000||(00) 00000-0000">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="cns" placeholder="CNS">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width">
            <input matInput formControlName="cbo" placeholder="CBO">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select formControlName="areaId" placeholder="Área de atuação"
              (selectionChange)="areaChange($event)" matInput>
              <mat-option>
                <ngx-mat-select-search [formControl]="searchSpecialtyArea" placeholderLabel="Pesquisar.."
                  noEntriesFoundLabel="Área de atuação não encontrada">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let area of filteredSpecialtiesArea | async" [value]="area.id">
                {{ area.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-select formControlName="specialtyId" (selectionChange)="specialtyChange($event)"
              placeholder="Especialidade" matInput>
              <mat-option>
                <ngx-mat-select-search [formControl]="searchSpecialty" placeholderLabel="Pesquisar.."
                  noEntriesFoundLabel="Especialidade não encontrada">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let sp of filteredSpecialties | async" [value]="sp.id">
                {{ sp.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="example-full-width" *ngIf="form.get('documentType').value">
            <input matInput formControlName="document" maxlength="15"
              [placeholder]="form.get('documentType').value">
          </mat-form-field>
        </div>
        <!-- <div class="col-md-3">
          <mat-form-field >
            <mat-label>UF</mat-label>
            <mat-select formControlName="uf">
              <mat-option *ngFor="let uf of ufs" [value]="uf">
                {{uf}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-12">
          <mat-form-field class="example-full-width">
            <input matInput currencyMask formControlName="value" placeholder="Valor da consulta">
          </mat-form-field>
        </div> -->
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form)" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
