export enum DialogSearchType {
	DOC = 'DOC',
	CLI = 'CLI',
  ONLY_DOC = 'ONLY_DOC'
}

export enum UserInfoRoles {
	DOCTOR = 'DOCTOR',
	ADMIN = 'ADMIN',
	SECRETARY = 'SECRETARY',
	PATIENT = 'PATIENT',
  PREFECTURE = 'PREFECTURE'
}

export var LinkTermsAndPolicy = 'https://falecomasi-docs.s3.amazonaws.com/Termo_Geral_de_Consentimento_para_Tratamento_de_Dados_Pessoais.pdf';
export var linkTermsService = 'https://falecomasi-docs.s3.amazonaws.com/Termo_de_Servico.pdf';
export var linkPrivatePolicy = 'https://falecomasi-docs.s3.amazonaws.com/Politica_de_Privacidade.pdf';
export var linkPrinciplesPolicy = 'https://falecomasi-docs.s3.amazonaws.com/Principios_de_Privacidade_e_Seguranca.pdf';
export var linkPrivateGuide = 'https://falecomasi-docs.s3.amazonaws.com/Guia_de_Privacidade.pdf';
