<table class="table table-responsive">
  <thead>
    <!-- <th></th> -->
    <th *ngFor="let col of columns" [class.hasorder]="col.order"
      [class.active-order]="pageControl.orderField === col.slug" (click)="onClickOrderBy(col.slug, col.order)">
      {{ col.title }}
      <i class="material-icons" *ngIf="pageControl.orderField === col.slug">
        {{ pageControl.order === 'DESC' ? 'arrow_downward' : 'arrow_upward' }}
      </i>
    </th>
  </thead>
  <tbody>
    <tr *ngIf="loading">
      <td [attr.colspan]="columns.length">
        <div class="loader-bubble loader-bubble-primary d-block"></div>
      </td>
    </tr>
    <ng-container *ngIf="!loading">
      <tr *ngIf="!list.length">
        <td [attr.colspan]="columns.length + 1" class="text-center">
          <div>Nenhum resultado encontrado</div>
        </td>
      </tr>
      <tr class="hover" style="outline: none; cursor: pointer" (click)="handleClickManager(l); $event.stopPropagation()"
        *ngFor="let l of list; let i = index">
        <!-- <td style="width: 45px;">
          <div class="photo-profile" style="background-image: url({{l.photo}})" *ngIf="l?.photo; else noPhoto"></div>
          <ng-template #noPhoto>
            <div class="no-photo">
              <i class="fal fa-user-md"></i>
            </div>
          </ng-template>
        </td> -->
        <td>
          <div>{{l.name}}</div>
          <div class="small">{{l?.cpf | cpfPipe}}</div>
        </td>
        <td>
          <div>{{l.email}}</div>
        </td>
        <td>
          <div>{{l.telephone | phonePipe}}</div>
        </td>
        <td>
          <button mat-icon-button (click)="clickDeleteManager.emit(l); $event.stopPropagation()">
            <i class="fal fa-trash-alt" style="margin-bottom: 8px;"></i>
          </button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
<mat-paginator [length]="pageControl.count" [pageIndex]="pageControl.page" [pageSizeOptions]="[5, 10, 25, 50, 100]"
  [pageSize]="pageControl.limit" (page)="pageEvent($event)"></mat-paginator>
