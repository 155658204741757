<mat-dialog-content>
  <div class="row mb-3">
    <div class="col-md-12">
      <h1>{{ title }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col">
      {{ message }}
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onDismiss()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onConfirm()" class="btn mx-3 btn-confirm">Confirmar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
