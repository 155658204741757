import { Component, OnInit, HostListener, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '@app-shared/utils';

import { SidebarService } from '@app-services/sidebar.service';
import { SessionQuery } from '@app-store/session/session.query';
import { UserInfoRoles } from '@app-shared/enums/others';

@Component({
	selector: 'app-layout-restrict-area-sidebar',
	templateUrl: './layout-restrict-area-sidebar.component.html',
	styleUrls: ['./layout-restrict-area-sidebar.component.scss']
})
export class LayoutRestrictAreaSidebarComponent implements OnInit {
	isMobile = false;
	itemsMenu = [];

  @Input() incompleteProfile = false;

  isBrowser = false;

	constructor(
    public router: Router,
    public sidebarService: SidebarService,
    private sessionQuery: SessionQuery,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

	ngOnInit() {
    this.initSidebar();

		this.updateSidebar();
		this.updateActiveMenu();
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
			if (Utils.isMobile()) {
				this.sidebarService.active = false;
			}
			this.updateActiveMenu();
		});
	}

  private initSidebar(): void {
    this._clearSideBar();
    this.sessionQuery.userInfo$.subscribe(info => {
      if (info) {
        if (info.role === UserInfoRoles.ADMIN) {
          this.itemsMenu = [
            this.insertDashboard(),
            this.insertSchedule(),
            this.insertHistory(),
            this.insertPatient(),
            this.insertExpertList(),
            this.insertManager()
          ];
        }

        if (info.role === UserInfoRoles.DOCTOR) {
          this.itemsMenu = [
            this.insertSchedule(),
            this.insertHistory(),
            this.insertPatient(),
            this.insertExpertProfile(info.id),
          ];
        }

        if (info.role === UserInfoRoles.SECRETARY) {
          this.itemsMenu = [
            this.insertSchedule(),
            this.insertHistory(),
            this.insertPatient(),
            this.insertExpertList(),
          ];
        }

        if (info.role === UserInfoRoles.PREFECTURE) {
          this.itemsMenu = [
            this.insertDashboard(),
            this.insertPatient(),
            this.insertExpertList(),
            this.insertManager(),
          ];
        }
      }
		});
  }

  insertDashboard() {
    if (!this.itemsMenu.find(i => i?.link === '/painel/dashboard')) {
      return {
        type: 'link',
        label: 'Dashboard',
        link: '/painel/dashboard',
        icon: 'fa-chart-network',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertSchedule() {
    if (!this.itemsMenu.find(i => i?.link === '/painel/schedule')) {
      return {
        type: 'link',
        label: 'Agendamento',
        link: '/painel/schedule',
        icon: 'fa-calendar-alt',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertHistory() {
    if (!this.itemsMenu.find(i => i.link === '/painel/history')) {
      return {
        type: 'link',
        label: 'Histórico',
        link: '/painel/history',
        icon: 'fa-history',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertPatient() {
    if (!this.itemsMenu.find(i => i.link === '/painel/patients')) {
      return {
        type: 'link',
        label: 'Pacientes',
        link: '/painel/patients',
        icon: 'fa-user',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertExpertList() {
    if (!this.itemsMenu.find(i => i.link === '/painel/experts')) {
      return {
        type: 'link',
        label: 'Especialistas',
        link: '/painel/experts',
        icon: 'fa-briefcase-medical',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertManager() {
    if (!this.itemsMenu.find(i => i.link === '/painel/manager')) {
      return {
        type: 'link',
        label: 'Administrativo',
        link: '/painel/manager',
        icon: 'fa-user-headset',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

  insertExpertProfile(id: string) {
    if (!this.itemsMenu.find(i => i.link === `/painel/experts/profile/${id}`)) {
      return {
        type: 'link',
        label: 'Meu Perfil',
        link: `/painel/experts/profile/${id}`,
        icon: 'fa-briefcase-medical',
        dropdown: false,
        children: []
      }
    }

    return null;
  }

	insertFreeTime() {
    if (!this.itemsMenu.find(i => i.link === '/painel/free-time')) {
      return {
        type: 'link',
        label: 'Horários vagos',
        link: '/painel/free-time',
        icon: 'fa-calendar-day',
        dropdown: false,
        children: []
      }
    }

    return null;
	}

	updateSidebar() {
		this.sidebarService.active = this.isBrowser && !Utils.isMobile();
		this.isMobile = this.isBrowser && !Utils.isMobile();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
	}

	setActiveMenu(item) {
		setTimeout(() => {
			item.dropdown = !item.dropdown;
		}, 0);
	}

	private updateActiveMenu() {
		const activeRoute = window.location.hash || window.location.pathname;
		this.itemsMenu.forEach(item => {
			item.dropdown = false;
			if (activeRoute.indexOf(item.link) !== -1) {
				item.dropdown = true;
			}
			if (item.children) {
				item.children.forEach(subItem => {
					subItem.active = false;
					if (activeRoute.indexOf(subItem.link) !== -1) {
						item.dropdown = true;
					}
				});
			}
		});
	}

	toggleSidebar() {
		this.sidebarService.minSidebar = !this.sidebarService.minSidebar;
	}

  private _clearSideBar(): void {
    this.itemsMenu = [];
  }
}
