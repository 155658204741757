<div mat-dialog-content class="dialog-search-schedule-class">
  <div class="row align-items-center justify-content-end">
    <div class="col-md-4 col-sm-6 col-12">
      <mat-form-field appearance="fill">
        <input matInput placeholder="Placeholder" [(ngModel)]="searchQuery" (keyup)="onSearch()">
        <i matPrefix class="fal fa-search"></i>
      </mat-form-field>
    </div>
  </div>
  <div class="container-table">
    <div class="container-internal">
      <table class="table table-responsive">
        <thead>
          <tr>
            <th *ngFor="let col of columns" [class.hasorder]="col.order"
              [class.active-order]="pageControl.orderField === col.slug" (click)="onClickOrderBy(col.slug, col.order)">
              {{ col.title }}
              <i class="material-icons" *ngIf="pageControl.orderField === col.slug">
                {{ pageControl.order === 'DESC' ? 'arrow_downward' : 'arrow_upward' }}
              </i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="loading" *ngIf="loading">
            <td [attr.colspan]="columns.length">
              <div class="loader-bubble loader-bubble-primary d-block"></div>
            </td>
          </tr>
          <ng-container *ngIf="!loading && isTypeDoctor">
            <ng-container *ngFor="let d of doctors">
              <tr *ngFor="let s of d.schedules; let i = index" class="hover" style="outline: none;"
                (click)="selectDoctor(d, s)" role="button">
                <td [ngStyle]="i>0 && {'border-top': 'none' }" class="td-name">
                  <span *ngIf="i === 0"><strong>{{ d.name }}</strong></span>
                </td>
                <td class="td-address">
                  <div>{{ s.clinic.name }}</div>
                  <small>{{ s.clinic.address }} {{ s.clinic.number }}, {{ s.clinic.neighborhood }}, {{ s.clinic.city }},
                    {{ s.clinic.uf }}</small>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!loading && isTypeOnlyDoctor">
            <ng-container *ngFor="let d of doctors">
              <tr (click)="selectOnlyDoctor(d)" class="hover" style="outline: none;" role="button">
                <td class="td-name">
                  <strong>{{ d.name }}</strong>
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!loading && type === dialogSearchType.CLI">
            <tr *ngFor="let c of clinics" class="hover" style="outline: none;" (click)="selectClinic(c)" role="button">
              <td class="td-name">
                <span><strong>{{ c.name }}</strong></span>
              </td>
              <td class="td-address">
                <div>{{ c.address }} {{ c.number }}</div>
                <small>{{ c.neighborhood }}, {{ c.city }}, {{ c.uf }}</small>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
