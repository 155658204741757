import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { MedicalRecord, MedicalRecordPageableResponse } from '@app-model/patient';
import { environment } from '@env/environment';
import { take, Observable } from 'rxjs';
import { ApiResponse } from '../model/application';

@Injectable({
  providedIn: 'root'
})
export class MedicalRecordService {

  constructor(
    private readonly http: HttpClient
  ) { }

  paginate(pageControl: PageControl, patientId: string, customFilter?): Observable<MedicalRecordPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField,
			value: pageControl.value,
      ...customFilter
		};

		return this.http.post<MedicalRecordPageableResponse>(`${environment.api}/patient/${patientId}/medical-record/search`, body).pipe(take(1));
	}

  postMedicalRecord(doctorId: string, patientId: string, medicalRecord: MedicalRecord): Observable<ApiResponse<MedicalRecord>> {
    return this.http.post<ApiResponse<MedicalRecord>>(`${environment.api}/doctor/${doctorId}/patient/${patientId}/medical-record`, medicalRecord);
  }
}
