<mat-dialog-content>
  <div class="container p-2 align-middle">
    <div class="row justify-content-end">
      <div class="col-md-auto">
        <button type="button" class="btn btn-close" (click)="onNoClick()"></button>
      </div>
    </div>
    <form class="p-2" [formGroup]="form" appInvalidControlScroll>
      <div class="row mb-3">
        <div class="col-md-12">
          <h1>{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>E-mail</mat-label>
            <input matInput formControlName="email" placeholder="E-mail"
              (blur)="getPatientByEmail($event.target.value)">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Nome completo</mat-label>
            <input matInput formControlName="name" placeholder="Nome completo">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>Data de nascimento</mat-label>
            <input matInput formControlName="birthDate" placeholder="Data de nascimento" type="text" mask="00/00/0000">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>CPF</mat-label>
            <input matInput formControlName="cpf" placeholder="CPF" type="text" mask="000.000.000-00">
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field>
            <mat-label>CNS</mat-label>
            <input matInput formControlName="cns" placeholder="CNS" type="text" mask="000 0000 0000 0000">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Telefone</mat-label>
            <input matInput formControlName="telephone" placeholder="Telefone" type="text" mask="(00) 0000-0000||(00) 00000-0000">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Nome da Mãe</mat-label>
            <input matInput formControlName="motherName" placeholder="Nome da Mãe">
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field>
            <mat-label>Endereço</mat-label>
            <input matInput formControlName="address" placeholder="Endereço">
          </mat-form-field>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput type="number" step="1" formControlName="credits" placeholder="Créditos">
          </mat-form-field>
        </div>
      </div> -->
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 btn-submit d-flex justify-content-center">
        <button type="button" (click)="onNoClick()" class="btn mx-3 btn-cancel">Cancelar</button>
        <button type="button" (click)="onSubmit(form, form.getRawValue())" class="btn mx-3 btn-confirm">Salvar</button>
      </div>
    </div>
  </div>
</mat-dialog-actions>
