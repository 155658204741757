import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Manager, ManagerPageableResponse, ManagerResponse } from '@app-model/manager';
import { PageControl } from '@app-model/page-control';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  constructor(private http: HttpClient) { }

  paginate(pageControl: PageControl, idClinic): Observable<ManagerPageableResponse> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}

		return this.http.post<ManagerPageableResponse>(`${environment.api}/clinic/${idClinic}/secretaries/search`, body);
	}

  getByEmail(email: string): Observable<ManagerResponse> {
    return this.http.get<ManagerResponse>(`${environment.api}/secretary/by-email/${email}`);
  }

  update(manager: Manager): Observable<ManagerResponse> {
    return this.http.put<ManagerResponse>(`${environment.api}/secretary/${manager.id}`, manager);
  }

  create(manager: Manager, idClinic: string): Observable<ManagerResponse> {
    return this.http.post<ManagerResponse>(`${environment.api}/clinic/${idClinic}/secretaries-associate`, manager)
  }

  delete(id: string, idClinic: string): Observable<any> {
    return this.http.delete<ManagerResponse>(`${environment.api}/clinic/${idClinic}/secretaries/${id}`)
  }
}
