import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageControl } from '@app-model/page-control';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetService {

  constructor(private http: HttpClient) { }

  paginate(pageControl: PageControl): Observable<any> {
		const body: any = {
			page: pageControl.page,
			pageSize: pageControl.limit,
			sortDirection: pageControl.order,
			sortField: pageControl.orderField
		};

		if (pageControl.param && pageControl.param !== '' && pageControl.value && pageControl.value !== '') {
			body.param = pageControl.param;
			body.value = pageControl.value;
		}

		return this.http.post<any>(`${environment.api}/meet/search`, body);
	}
}
