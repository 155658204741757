import { SelectScheduleComponent } from './select-schedule/select-schedule.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';

import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';

import { BtnLoadingComponent } from './btn-loading/btn-loading.component';
import { LoaderComponent } from './loader/loader.component';
import { Ng2FileDropComponent } from './ng2-file-drop/ng2-file-drop.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { LegendComponent } from './legend/legend.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

const components = [
	BtnLoadingComponent,
	Ng2FileDropComponent,
	LoaderComponent,
	StarRatingComponent,
	SelectScheduleComponent,
	LegendComponent,
  ConfirmDialogComponent
];

@NgModule({
	imports: [
	CommonModule,
		RouterModule,
		SharedPipesModule,
		SharedDirectivesModule,
		NgbModule,
		FileUploadModule,
    MatDialogModule,
	],
	declarations: components,
	exports: components
})
export class SharedComponentsModule {}
