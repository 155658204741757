import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableDashboardComponent } from './table-dashboard.component';
import { SharedPipesModule } from '@app-shared/pipes/shared-pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    TableDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    MatPaginatorModule,
    MatButtonModule
  ],
  exports: [
    TableDashboardComponent
  ]
})
export class TableDashboardModule { }
